<template>
  <div>
    <b-modal
      id="slider-modal"
      size="xl"
      hide-footer
      hide-header
      hide-header-close
      centered
    >
      <ProfileSlider />
    </b-modal>
    <div class="profile-wrapper-main">
      <div class="container-main container-main-width">
        <h1 class="main-title-page">{{ $t("Profile") }}</h1>
      </div>
    </div>
    <div
      class="container-main position-relative container-main-profile-mobile container-main-width"
      style="margin-bottom: 40rem"
    >
      <div class="profile-main-block">
        <div class="profile-avatar-block profile-avatar-block-mr">
          <div class="profile-user">
            <div v-bind:class="{ 'new': status=='new', 'pro': status=='pro','master':status=='master' }"
                 class="profile-user-avatar-item-wrapper">
              <svg v-if="status=='new'" xmlns="http://www.w3.org/2000/svg" width="198" height="182"
                   viewBox="0 0 198 182" fill="none">
                <circle cx="99.0811" cy="80.7086" r="75.6802" fill="white"/>
                <mask id="mask0_2726_38072" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="25" y="8" width="148"
                      height="147">
                  <ellipse cx="99.0805" cy="81.2813" rx="73.713" ry="72.8805"
                           transform="rotate(-0.446511 99.0805 81.2813)" fill="#FAFAFA"/>
                </mask>

                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.2872 148.281H128.258C154.645 137.052 173.125 110.958 173.125 80.5854C173.125 39.9718 140.082 7.00743 99.2726 7.00743C58.4628 7.00743 25.4206 39.9718 25.4206 80.5854C25.4206 110.958 43.8997 137.052 70.2872 148.281ZM55.3876 148.281C33.1369 133.925 18.4132 108.969 18.4132 80.5854C18.4132 36.0793 54.6152 0 99.2726 0C143.93 0 180.132 36.0793 180.132 80.5854C180.132 108.969 165.408 133.925 143.158 148.281H193.689C196.96 148.281 198.979 151.852 197.291 154.654L181.918 180.189C181.158 181.452 179.791 182.225 178.316 182.225H19.5841C18.1095 182.225 16.7426 181.452 15.982 180.189L0.608922 154.654C-1.07815 151.852 0.940058 148.281 4.21097 148.281H55.3876Z"
                      fill="#C460C0"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.2872 148.281H128.258C154.645 137.052 173.125 110.958 173.125 80.5854C173.125 39.9718 140.082 7.00743 99.2726 7.00743C58.4628 7.00743 25.4206 39.9718 25.4206 80.5854C25.4206 110.958 43.8997 137.052 70.2872 148.281ZM55.3876 148.281C33.1369 133.925 18.4132 108.969 18.4132 80.5854C18.4132 36.0793 54.6152 0 99.2726 0C143.93 0 180.132 36.0793 180.132 80.5854C180.132 108.969 165.408 133.925 143.158 148.281H193.689C196.96 148.281 198.979 151.852 197.291 154.654L181.918 180.189C181.158 181.452 179.791 182.225 178.316 182.225H19.5841C18.1095 182.225 16.7426 181.452 15.982 180.189L0.608922 154.654C-1.07815 151.852 0.940058 148.281 4.21097 148.281H55.3876Z"
                      fill="url(#paint0_linear_2726_38072)"/>

                <defs>
                  <filter id="filter0_d_2726_38072" x="65.2603" y="157.997" width="68.0415" height="25.6119"
                          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="2.80297"/>
                    <feGaussianBlur stdDeviation="2.80297"/>
                    <feColorMatrix type="matrix"
                                   values="0 0 0 0 0.150395 0 0 0 0 0.139931 0 0 0 0 0.216667 0 0 0 0.29 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2726_38072"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2726_38072" result="shape"/>
                  </filter>
                  <linearGradient id="paint0_linear_2726_38072" x1="151.45" y1="146" x2="83.1881" y2="218.439"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C460C0"/>
                    <stop offset="1" stop-color="#305AA6"/>
                  </linearGradient>
                </defs>
              </svg>
              <svg v-if="status=='pro'"
                   xmlns="http://www.w3.org/2000/svg" width="306" height="180" viewBox="0 0 306 180" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M22.7942 70.5549C12.4545 70.5549 4.065 62.2053 4.00038 51.8809H84.126V70.5549H22.7942ZM0 51.7607C0 49.6179 1.73708 47.8809 3.87987 47.8809H84.126H88.126V51.8809V70.5549V70.6273H101.752H105.752V74.6273V93.3014V93.3739H122.314H126.314V97.3739V116.048V120.048H122.314H60.9825C48.3936 120.048 38.1883 109.843 38.1883 97.2538C38.1883 97.2337 38.1884 97.2136 38.1887 97.1936C26.6471 96.0722 17.626 86.3432 17.626 74.5072C17.626 74.3266 17.6383 74.1489 17.6622 73.9749C7.54472 71.647 0 62.5849 0 51.7607ZM40.4202 93.3014C30.0805 93.3014 21.691 84.9518 21.6264 74.6273H101.752V93.3014H40.4202ZM42.1886 97.3739C42.2533 107.698 50.6428 116.048 60.9825 116.048H122.314V97.3739H42.1886ZM283.206 70.5549C293.546 70.5549 301.935 62.2053 302 51.8809H221.874V70.5549H283.206ZM306 51.7607C306 49.6179 304.263 47.8809 302.12 47.8809H221.874H217.874V51.8809V70.5549V70.6273H204.248H200.248V74.6273V93.3014V93.3739H183.686H179.686V97.3739V116.048V120.048H183.686H245.018C257.607 120.048 267.812 109.843 267.812 97.2538C267.812 97.2336 267.812 97.2136 267.812 97.1935C279.353 96.0718 288.374 86.343 288.374 74.5072C288.374 74.3266 288.362 74.1489 288.338 73.9749C298.455 71.647 306 62.585 306 51.7607ZM265.58 93.3014C275.919 93.3014 284.309 84.9518 284.374 74.6273H204.248V93.3014H265.58ZM263.812 97.3739C263.747 107.698 255.358 116.048 245.018 116.048H183.686V97.3739H263.812Z"
                      fill="url(#paint0_linear_2726_38253)"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M22.7942 70.5549C12.4545 70.5549 4.065 62.2053 4.00038 51.8809H84.126V70.5549H22.7942ZM0 51.7607C0 49.6179 1.73708 47.8809 3.87987 47.8809H84.126H88.126V51.8809V70.5549V70.6273H101.752H105.752V74.6273V93.3014V93.3739H122.314H126.314V97.3739V116.048V120.048H122.314H60.9825C48.3936 120.048 38.1883 109.843 38.1883 97.2538C38.1883 97.2337 38.1884 97.2136 38.1887 97.1936C26.6471 96.0722 17.626 86.3432 17.626 74.5072C17.626 74.3266 17.6383 74.1489 17.6622 73.9749C7.54472 71.647 0 62.5849 0 51.7607ZM40.4202 93.3014C30.0805 93.3014 21.691 84.9518 21.6264 74.6273H101.752V93.3014H40.4202ZM42.1886 97.3739C42.2533 107.698 50.6428 116.048 60.9825 116.048H122.314V97.3739H42.1886ZM283.206 70.5549C293.546 70.5549 301.935 62.2053 302 51.8809H221.874V70.5549H283.206ZM306 51.7607C306 49.6179 304.263 47.8809 302.12 47.8809H221.874H217.874V51.8809V70.5549V70.6273H204.248H200.248V74.6273V93.3014V93.3739H183.686H179.686V97.3739V116.048V120.048H183.686H245.018C257.607 120.048 267.812 109.843 267.812 97.2538C267.812 97.2336 267.812 97.2136 267.812 97.1935C279.353 96.0718 288.374 86.343 288.374 74.5072C288.374 74.3266 288.362 74.1489 288.338 73.9749C298.455 71.647 306 62.585 306 51.7607ZM265.58 93.3014C275.919 93.3014 284.309 84.9518 284.374 74.6273H204.248V93.3014H265.58ZM263.812 97.3739C263.747 107.698 255.358 116.048 245.018 116.048H183.686V97.3739H263.812Z"
                      fill="url(#paint1_linear_2726_38253)"/>
                <mask id="mask0_2726_38253" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="78" y="4" width="151"
                      height="151">
                  <ellipse cx="153.5" cy="79.9999" rx="75.4861" ry="75" fill="#FAFAFA"/>
                </mask>

                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M119.746 147.189H186.635C211.417 134.916 228.44 109.428 228.44 79.992C228.44 38.5901 194.765 4.99994 153.191 4.99994C111.617 4.99994 77.9416 38.5901 77.9416 79.992C77.9416 109.428 94.9645 134.916 119.746 147.189ZM109.637 147.189C87.5543 132.939 72.9416 108.166 72.9416 79.992C72.9416 35.8136 108.87 -6.10352e-05 153.191 -6.10352e-05C197.511 -6.10352e-05 233.44 35.8136 233.44 79.992C233.44 108.166 218.827 132.939 196.744 147.189H248.97C251.303 147.189 252.743 149.737 251.54 151.736L234.87 179.43C234.327 180.332 233.352 180.883 232.3 180.883H73.4401C72.3878 180.883 71.4125 180.332 70.8698 179.43L54.2 151.736C52.9964 149.737 54.4365 147.189 56.7703 147.189H109.637Z"
                      fill="url(#paint2_linear_2726_38253)"/>

                <defs>
                  <filter id="filter0_d_2726_38253" x="121.775" y="155.974" width="63.1523" height="22.976"
                          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feColorMatrix type="matrix"
                                   values="0 0 0 0 0.150395 0 0 0 0 0.139931 0 0 0 0 0.216667 0 0 0 0.29 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2726_38253"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2726_38253" result="shape"/>
                  </filter>
                  <linearGradient id="paint0_linear_2726_38253" x1="0" y1="81.9645" x2="306" y2="81.9645"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CC2B5E"/>
                    <stop offset="1" stop-color="#753A88"/>
                  </linearGradient>
                  <linearGradient id="paint1_linear_2726_38253" x1="234.177" y1="102.497" x2="223.09" y2="151.13"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C460C0"/>
                    <stop offset="1" stop-color="#305AA6"/>
                  </linearGradient>
                  <linearGradient id="paint2_linear_2726_38253" x1="205.452" y1="144.925" x2="137.731" y2="217.435"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C460C0"/>
                    <stop offset="1" stop-color="#305AA6"/>
                  </linearGradient>
                </defs>
              </svg>
              <svg v-if="status=='master'" xmlns="http://www.w3.org/2000/svg" width="306" height="244"
                   viewBox="0 0 306 244" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M22.7738 133.432C12.4465 133.432 4.06689 125.093 4.0004 114.781H84.126V133.432H22.7738ZM0 114.658C0 112.517 1.73552 110.781 3.87639 110.781H84.126H88.126V114.781V133.432V133.508H101.752H105.752V137.508V156.158V156.234H122.314H126.314V160.234V178.884V182.884H122.314H60.962C48.3844 182.884 38.1883 172.688 38.1883 160.11C38.1883 160.091 38.1884 160.071 38.1887 160.052C26.6486 158.94 17.626 149.216 17.626 137.384C17.626 137.205 17.6381 137.028 17.6617 136.856C7.54558 134.535 0 125.478 0 114.658ZM40.3998 156.158C30.0725 156.158 21.6929 147.819 21.6264 137.508H101.752V156.158H40.3998ZM42.1887 160.234C42.2552 170.545 50.6348 178.884 60.962 178.884H122.314V160.234H42.1887ZM283.227 133.432C293.554 133.432 301.934 125.093 302 114.781H221.874V133.432H283.227ZM306 114.658C306 112.517 304.265 110.781 302.124 110.781H221.874H217.874V114.781V133.432V133.508H204.249H200.249V137.508V156.158V156.234H183.687H179.687V160.234V178.884V182.884H183.687H245.039C257.616 182.884 267.813 172.688 267.813 160.11C267.813 160.091 267.812 160.071 267.812 160.052C279.352 158.94 288.375 149.216 288.375 137.384C288.375 137.205 288.363 137.028 288.339 136.855C298.455 134.535 306 125.478 306 114.658ZM265.601 156.158C275.929 156.158 284.308 147.819 284.375 137.508H204.249V156.158H265.601ZM263.812 160.234C263.746 170.545 255.366 178.884 245.039 178.884H183.687V160.234H263.812Z"
                      fill="url(#paint0_linear_2726_38440)"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M22.7738 133.432C12.4465 133.432 4.06689 125.093 4.0004 114.781H84.126V133.432H22.7738ZM0 114.658C0 112.517 1.73552 110.781 3.87639 110.781H84.126H88.126V114.781V133.432V133.508H101.752H105.752V137.508V156.158V156.234H122.314H126.314V160.234V178.884V182.884H122.314H60.962C48.3844 182.884 38.1883 172.688 38.1883 160.11C38.1883 160.091 38.1884 160.071 38.1887 160.052C26.6486 158.94 17.626 149.216 17.626 137.384C17.626 137.205 17.6381 137.028 17.6617 136.856C7.54558 134.535 0 125.478 0 114.658ZM40.3998 156.158C30.0725 156.158 21.6929 147.819 21.6264 137.508H101.752V156.158H40.3998ZM42.1887 160.234C42.2552 170.545 50.6348 178.884 60.962 178.884H122.314V160.234H42.1887ZM283.227 133.432C293.554 133.432 301.934 125.093 302 114.781H221.874V133.432H283.227ZM306 114.658C306 112.517 304.265 110.781 302.124 110.781H221.874H217.874V114.781V133.432V133.508H204.249H200.249V137.508V156.158V156.234H183.687H179.687V160.234V178.884V182.884H183.687H245.039C257.616 182.884 267.813 172.688 267.813 160.11C267.813 160.091 267.812 160.071 267.812 160.052C279.352 158.94 288.375 149.216 288.375 137.384C288.375 137.205 288.363 137.028 288.339 136.855C298.455 134.535 306 125.478 306 114.658ZM265.601 156.158C275.929 156.158 284.308 147.819 284.375 137.508H204.249V156.158H265.601ZM263.812 160.234C263.746 170.545 255.366 178.884 245.039 178.884H183.687V160.234H263.812Z"
                      fill="url(#paint1_linear_2726_38440)"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M152.93 0L156.512 6.59474L163.8 8.27576L158.736 13.836L159.6 21.7239L152.93 18.2325L146.137 21.7239L147.125 13.836L142.061 8.27576L149.348 6.59474L152.93 0ZM192.446 49.0864L181.438 58.0986C175.006 63.4172 166.101 60.4624 162.885 52.0412L154.474 30.6188C153.732 28.8459 151.629 28.8459 151.011 30.6188L142.847 51.8935C139.508 60.6102 130.355 63.4172 123.923 57.8031L113.285 48.4954C111.801 47.1658 109.699 48.9386 110.317 51.1548L121.449 78.4868C121.573 78.93 121.944 79.0778 122.191 79.0778H183.664C184.035 79.0778 184.283 78.7823 184.407 78.4868L195.291 51.8935C196.033 49.6773 193.931 47.9045 192.446 49.0864ZM111.677 42.5862C111.677 45.1156 109.961 47.1662 107.843 47.1662C105.725 47.1662 104.009 45.1156 104.009 42.5862C104.009 40.0567 105.725 38.0062 107.843 38.0062C109.961 38.0062 111.677 40.0567 111.677 42.5862ZM201.475 42.5862C201.475 45.1156 199.759 47.1662 197.641 47.1662C195.523 47.1662 193.807 45.1156 193.807 42.5862C193.807 40.0568 195.523 38.0062 197.641 38.0062C199.759 38.0062 201.475 40.0568 201.475 42.5862Z"
                      fill="url(#paint2_linear_2726_38440)"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M152.93 0L156.512 6.59474L163.8 8.27576L158.736 13.836L159.6 21.7239L152.93 18.2325L146.137 21.7239L147.125 13.836L142.061 8.27576L149.348 6.59474L152.93 0ZM192.446 49.0864L181.438 58.0986C175.006 63.4172 166.101 60.4624 162.885 52.0412L154.474 30.6188C153.732 28.8459 151.629 28.8459 151.011 30.6188L142.847 51.8935C139.508 60.6102 130.355 63.4172 123.923 57.8031L113.285 48.4954C111.801 47.1658 109.699 48.9386 110.317 51.1548L121.449 78.4868C121.573 78.93 121.944 79.0778 122.191 79.0778H183.664C184.035 79.0778 184.283 78.7823 184.407 78.4868L195.291 51.8935C196.033 49.6773 193.931 47.9045 192.446 49.0864ZM111.677 42.5862C111.677 45.1156 109.961 47.1662 107.843 47.1662C105.725 47.1662 104.009 45.1156 104.009 42.5862C104.009 40.0567 105.725 38.0062 107.843 38.0062C109.961 38.0062 111.677 40.0567 111.677 42.5862ZM201.475 42.5862C201.475 45.1156 199.759 47.1662 197.641 47.1662C195.523 47.1662 193.807 45.1156 193.807 42.5862C193.807 40.0568 195.523 38.0062 197.641 38.0062C199.759 38.0062 201.475 40.0568 201.475 42.5862Z"
                      fill="url(#paint3_linear_2726_38440)"/>
                <mask id="mask0_2726_38440" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="78" y="67" width="151"
                      height="151">
                  <ellipse cx="153.5" cy="142.872" rx="75.4861" ry="74.9327" fill="#FAFAFA"/>
                </mask>
                <g mask="url(#mask0_2726_38440)">
                  <ellipse cx="151.999" cy="142.372" rx="76.9858" ry="75.4323" fill="white"/>

                </g>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M119.753 210.001H186.627C211.414 197.74 228.439 172.273 228.439 142.864C228.439 101.506 194.768 67.9435 153.19 67.9435C111.612 67.9435 77.941 101.506 77.941 142.864C77.941 172.273 94.966 197.74 119.753 210.001ZM109.636 210.001C87.5535 195.763 72.941 171.013 72.941 142.864C72.941 98.725 108.87 62.9435 153.19 62.9435C197.51 62.9435 233.439 98.725 233.439 142.864C233.439 171.013 218.827 195.763 196.744 210.001H248.968C251.302 210.001 252.742 212.549 251.537 214.549L234.871 242.212C234.328 243.113 233.353 243.664 232.301 243.664H73.4398C72.3879 243.664 71.4129 243.113 70.8701 242.212L54.2035 214.549C52.9989 212.549 54.4389 210.001 56.7732 210.001H109.636Z"
                      fill="url(#paint4_linear_2726_38440)"/>

                <defs>
                  <filter id="filter0_d_2726_38440" x="120.089" y="219.452" width="67.9502" height="22.304"
                          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feColorMatrix type="matrix"
                                   values="0 0 0 0 0.150395 0 0 0 0 0.139931 0 0 0 0 0.216667 0 0 0 0.29 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2726_38440"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2726_38440" result="shape"/>
                  </filter>
                  <linearGradient id="paint0_linear_2726_38440" x1="0" y1="144.834" x2="306" y2="144.834"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CC2B5E"/>
                    <stop offset="1" stop-color="#753A88"/>
                  </linearGradient>
                  <linearGradient id="paint1_linear_2726_38440" x1="234.177" y1="165.348" x2="223.109" y2="213.943"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C460C0"/>
                    <stop offset="1" stop-color="#305AA6"/>
                  </linearGradient>
                  <linearGradient id="paint2_linear_2726_38440" x1="139.959" y1="25.0049" x2="166.727" y2="25.0049"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F99930"/>
                    <stop offset="0.9998" stop-color="#EF6E77"/>
                    <stop offset="0.9999" stop-color="#F37E5D"/>
                    <stop offset="1" stop-color="#F37D5E"/>
                  </linearGradient>
                  <linearGradient id="paint3_linear_2726_38440" x1="178.598" y1="63.3577" x2="149.429" y2="98.4878"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C460C0"/>
                    <stop offset="1" stop-color="#305AA6"/>
                  </linearGradient>
                  <linearGradient id="paint4_linear_2726_38440" x1="205.451" y1="207.738" x2="137.795" y2="280.242"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C460C0"/>
                    <stop offset="1" stop-color="#305AA6"/>
                  </linearGradient>
                  <clipPath id="clip0_2726_38440">
                    <rect width="145" height="145" fill="white" transform="translate(84 98.9999)"/>
                  </clipPath>
                </defs>
              </svg>
              <img class="profile-user-avatar-item" :src="profile.avatar"/>
              <span v-if="status=='new'" class="profile-user-avatar-item-status">Новичок</span>
              <span v-if="status=='pro'" class="profile-user-avatar-item-status">Про</span>
              <span v-if="status=='master'" class="profile-user-avatar-item-status">Мастер</span>
            </div>
            <div class="profile-user-name">
              {{ profile.first_name }} {{ profile.last_name }}
            </div>
            <div class="profile-user-email">{{ profile.email }}</div>
          </div>
          <button
            @click="$bvModal.show('avatar-select')"
            class="avatar-profile-upload"
          >
            {{ $t("Change_avatar") }}
          </button>
        </div>
        <div class="profile-info-block">
          <div class="profile-tabs">
            <div
              :class="{ 'profile-tab-active': currentTab == 'main' }"
              @click="currentTab = 'main'"
              class="profile-tab"
            >
              {{ $t("Main") }}
            </div>
            <div
              :class="{ 'profile-tab-active': currentTab == 'password' }"
              @click="currentTab = 'password'"
              class="profile-tab"
            >
              {{ $t("Reset_password") }}
            </div>
            <div
              :class="{ 'profile-tab-active': currentTab == 'settings' }"
              @click="currentTab = 'settings'"
              class="profile-tab"
            >
              {{ $t("Notification_settings") }}
            </div>
          </div>
          <div class="profile-wrapper-content">
            <div v-if="currentTab == 'main'">
              <div class="profile-inputs-wrapper">
                <div class="profile-inputs-column">
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="email">{{
                        $t("IIN")
                      }}</label>
                    <input
                      disabled
                      v-model="profile.iin"
                      autocomplete="false"
                      class="smart-oqu-input-theme"
                      type="text"
                      :placeholder="$t('IIN')"
                      id="last_name"
                    />
                  </div>
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="email">{{
                        $t("Surname")
                      }}</label>
                    <input
                      v-model="profile.last_name"
                      autocomplete="false"
                      class="smart-oqu-input-theme"
                      type="text"
                      :placeholder="$t('Surname')"
                      id="last_name"
                    />
                  </div>
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="email">{{
                        $t("Email")
                      }}</label>
                    <input
                      v-model="profile.email"
                      autocomplete="false"
                      class="smart-oqu-input-theme"
                      type="text"
                      :placeholder="$t('Email')"
                      id="email"
                    />
                  </div>
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="parent">{{
                        $t("Name_of_parent")
                      }}</label>
                    <input
                      v-model="profile.parent_name"
                      autocomplete="false"
                      class="smart-oqu-input-theme"
                      type="text"
                      :placeholder="$t('Name_of_parent')"
                      id="parent"
                    />
                  </div>
                </div>
                <div class="profile-inputs-column">
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="last_name">{{
                        $t("Name")
                      }}</label>
                    <input
                      v-model="profile.first_name"
                      autocomplete="false"
                      class="smart-oqu-input-theme"
                      type="text"
                      :placeholder="$t('Name')"
                      id="first_name"
                    />
                  </div>
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="phone">{{
                        $t("Mobile_phone")
                      }}</label>
                    <input
                      v-model="profile.phone"
                      :key="phoneKey"
                      v-mask="'+7 (###) ### ##-##'"
                      autocomplete="false"
                      class="smart-oqu-input-theme"
                      type="text"
                      placeholder="+7 (___) ___ __-__"
                      id="phone"
                    />
                  </div>
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="phoneParent">{{
                        $t("Parent's_mobile_phone")
                      }}</label>
                    <input
                      v-model="profile.parent_phone"
                      :key="phoneKey"
                      v-mask="'+7 (###) ### ##-##'"
                      autocomplete="false"
                      class="smart-oqu-input-theme"
                      type="text"
                      placeholder="+7 (___) ___ __-__"
                      id="phoneParent"
                    />
                  </div>
                </div>
              </div>
              <div class="profile-update" @click="updateProfile">
                {{ $t("Update_Profile") }}
              </div>
            </div>
            <div v-if="currentTab == 'password'">
              <div class="profile-inputs-wrapper">
                <div class="profile-inputs-column">
                  <div class="password-required-name">
                    {{ $t("Your_password_must") }}:
                  </div>

                  <div class="recommendation-block">
                    {{ $t("Consist_of_more_than_8_characters") }}
                  </div>
                  <div class="recommendation-block">
                    {{ $t("Contain_at_least_one_capital_letter") }}
                  </div>
                  <div class="recommendation-block">
                    {{ $t("Contain_at_least_one_number") }}
                  </div>
                  <div class="recommendation-block">
                    {{ $t("Contain_special_characters") }}
                  </div>
                </div>
                <div class="profile-inputs-column">
                  <div class="strong-password-recommendation">
                    {{ $t("Choose_security_password") }}
                    <div class="lock-key-profile"></div>
                  </div>
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="password">
                      {{ $t("New_Password") }}
                    </label>
                    <input
                      v-model="changePassword.newPassword"
                      autocomplete="false"
                      class="smart-oqu-input-theme"
                      :type="showPassword ? 'text' : 'password'"
                      name="new_password"
                      :placeholder="$t('New_Password')"
                      id="password"
                    />
                    <div
                      :class="{
                        'show-pass': true,
                        show: !!showPassword,
                        hide: !showPassword,
                      }"
                      @click="showPassword = !showPassword"
                    />
                  </div>
                  <div class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="password-repeat">
                      {{ $t("Repeat_password") }}
                    </label>
                    <input
                      v-model="changePassword.newPasswordRepeat"
                      autocomplete="false"
                      class="smart-oqu-input-theme"
                      :type="showPasswordRepeat ? 'text' : 'password'"
                      name="new_password_repeat"
                      :placeholder="$t('Repeat_password')"
                      id="password-repeat"
                    />
                    <div
                      :class="{
                        'show-pass': true,
                        show: !!showPasswordRepeat,
                        hide: !showPasswordRepeat,
                      }"
                      @click="showPasswordRepeat = !showPasswordRepeat"
                    />
                  </div>
                  <div
                    class="profile-update password-update"
                    @click="changePasswordHandler"
                  >
                    {{ $t("Update_Profile") }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="currentTab == 'settings'">
              <div class="profile-inputs-wrapper">
                <div class="profile-inputs-column">
                  <div class="strong-password-recommendation">
                    {{ $t("Allow_notifications") }}
                    <div class="lock-key-profile"></div>
                  </div>
                  <div class="switch-form-main">
                    <div>{{ $t("System") }}</div>
                    <div>
                      <label class="form-switch">
                        <input
                          @change="updateNotificationSettings"
                          type="checkbox"
                          v-model="system"
                        />
                        <i></i>
                      </label>
                    </div>
                  </div>
                  <!--                  <div class="switch-form-main">-->
                  <!--                    <div>{{ $t("SMS_notifications") }}</div>-->
                  <!--                    <div>-->
                  <!--                      <label class="form-switch">-->
                  <!--                        <input-->
                  <!--                          @change="updateNotificationSettings"-->
                  <!--                          type="checkbox"-->
                  <!--                          v-model="sms"-->
                  <!--                        />-->
                  <!--                        <i></i>-->
                  <!--                      </label>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="switch-form-main">
                    <div>{{ $t("Email") }}</div>
                    <div>
                      <label class="form-switch">
                        <input
                          @change="updateNotificationSettings"
                          type="checkbox"
                          v-model="email"
                        />
                        <i></i>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="profile-inputs-column">
                  <div style="margin-top: 0" class="smart-oqu-group-theme">
                    <label class="smart-oqu-label-theme" for="lang">{{
                        $t("Notification_language")
                      }}</label>
                    <select
                      @change="updateNotificationSettings"
                      v-model="language"
                      autocomplete="false"
                      class="smart-oqu-input-theme"
                      type="text"
                      name=""
                      :placeholder="$t('Notification_language')"
                      id="lang"
                    >
                      <option value="ru">{{ $t('ru') }}</option>
                      <option value="kz">{{ $t('kz') }}</option>
                    </select>
                  </div>

                  <div class="notifications-images"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="profile-logout-button" @click="logoutMethod">
            <span>{{ $t("Logout") }}</span>
          </div>
          <div class="profile-coin-block">
            {{ $t("Number_of_coins") }}:
            <span class="profile-coin-item">{{ activeUserCoins }}</span>
          </div>
        </div>
        <div class="profile-info-block-mobile">
          <div class="profile-info-mobile-coins">
            {{ $t("Number_of_coins") }}:
            <span class="profile-coin-item-mobile">{{ activeUserCoins }}</span>
          </div>
          <div class="profile-info-mobile-main" @click="goToSection('main')">
            {{ $t("Main") }}
          </div>
          <div
            class="profile-info-mobile-reset_pass"
            @click="goToSection('password')"
          >
            {{ $t("Reset_password") }}
          </div>
          <div
            class="profile-info-mobile-notification-settings"
            @click="goToSection('settings')"
          >
            {{ $t("Notification_settings") }}
          </div>
          <div class="profile-logout-button-mobile" @click="logoutMethod">
            <span>{{ $t("Logout") }}</span>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="avatar-select"
      size="xl"
      hide-footer
      hide-header
      hide-header-close
      centered
    >
      <div class="profile-modal-wrapper">
        <button
          @click="$bvModal.hide('avatar-select')"
          class="close-modal-profile"
        ></button>
        <h1>{{ $t("Change_avatar") }}</h1>
        <div class="avatar-upload-block-wrapper">
          <div class="avatar-profile-example">
            <div
              v-for="avatar in defaultAvatars"
              :style="{ 'background-image': `url('./avatars/${avatar}')` }"
              class="avatar-profile-image"
              :key="avatar"
              @click="chooseDefaultAvatar(avatar)"
            />
          </div>
          <div class="avatar-upload">
            <span class="your-avatar">{{ $t("Your_avatar") }}</span>
            <div
              :class="`avatar-round ${isDefaultAvatar ? 'default' : ''}`"
              :style="{ 'background-image': `url(${avatar})` }"
            />

            <el-upload
              class="upload-demo"
              :action="apiRoot + '/api/user/avatar/upload'"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :headers="{
                Authorization: 'Bearer ' +token,
              }"
              :limit="1"
            >
              <button class="avatar-profile-upload-logo">
                {{ $t("Upload_photo") }}
              </button>
            </el-upload>
          </div>
        </div>
        <div class="button-profile-safe">
          <button @click="submitAvatar">
            {{ $t("Save") }}
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="data-change"
      size="lg"
      hide-footer
      hide-header
      hide-header-close
      centered
    >
      <div class="profile-modal-wrapper">
        <button
          @click="$bvModal.hide('data-change')"
          class="close-modal-profile"
        ></button>
        <div class="saved_info_modal_title">{{ $t("saved_info") }}</div>
        <div class="saved_info_modal_check"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Profile from "@/models/profile";
import VueMask from "v-mask";
import Vue from "vue";

export default {
  name: "Navbar",
  components: {
    'ProfileSlider': () => import('@/views/ProfileStudent/ProfileSlider.vue')
  },
  data() {
    return {
      status: 'new',
      isDefaultAvatar: true,
      defaultAvatars: [
        "Avatar-1.svg",
        "Avatar-2.svg",
        "Avatar-3.svg",
        "Avatar-4.svg",
        "Avatar-5.svg",
        "Avatar-6.svg",
        "Avatar-7.svg",
        "Avatar-8.svg",
        "Avatar-9.svg",
        "Avatar-10.svg",
        "Avatar-11.svg",
        "Avatar-12.svg",
        "Avatar-13.svg",
        "Avatar-14.svg",
        "Avatar-15.svg",
        "Avatar-16.svg",
        "Avatar-17.svg",
        "Avatar-18.svg",
        "Avatar-19.svg",
        "Avatar-20.svg",
        "Avatar-21.svg",
        "Avatar-22.svg",
        "Avatar-23.svg",
        "Avatar-24.svg",
      ],
      activeUserCoins: 0,
      totalUserCoins: 0,
      avatar: null,
      token: localStorage.getItem('token'),
      showPassword: false,
      showPasswordRepeat: false,
      phoneKey: 0,
      apiRoot: window.API_ROOT,
      currentTab: "main",
      profile: new Profile({}),
      userCoins: null,
      system: true,
      sms: true,
      email: true,
      language: "ru",
      changePassword: {
        newPassword: null,
        newPasswordRepeat: null,
      },
    };
  },
  methods: {
    updateNotificationSettings() {
      this.$http.post(API_ROOT + "/api/user/notification-settings", {
        system: this.system,
        sms: this.sms,
        email: this.email,
        language: this.language,
      });
    },
    getUserInfo() {
      this.$http
        .get(API_ROOT + "/api/user")
        .then((res) => {
          const profile = res.body
            ? {...res.body, avatar: this.getAvatar(res.body.avatar)}
            : {};
          this.activeUserCoins = res.body.coins.active_coins
          this.totalUserCoins = res.body.coins.total_coins
          if (this.activeUserCoins <= 99) {
            this.status = 'new'
          }
          else if (this.activeUserCoins >= 100 && this.activeUserCoins<=499) {
            this.status = 'pro'
          }
          else if (this.activeUserCoins >= 500) {
            this.status = 'master'
          }


          this.profile = new Profile(profile);
          this.avatar = this.getAvatar(res.body.avatar);
          const splitedPath = res.body.avatar.split("/");
          const avatarName = splitedPath[splitedPath.length - 1];
          this.isDefaultAvatar = this.defaultAvatars.indexOf(avatarName) !== -1;

          localStorage.setItem('coins', res.body.coins.active_coins);

          this.system = res.body.notification_settings?.system ?? 1;
          this.sms = res.body.notification_settings?.sms ?? 1;
          this.email = res.body.notification_settings?.email ?? 1;
          this.language = res.body.notification_settings?.language ?? "ru";
          this.phoneKey++;

          if (this.profile.avatar.substr(0, 4) == 'data') {
            if (res.body.gender == 1) {
              this.chooseDefaultAvatar('Avatar-1.svg', true)
            } else {
              this.chooseDefaultAvatar('Avatar-7.svg', true)

            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
        });
    },
    getNumbersOnly(phone) {
      return phone
        ? phone
          .replaceAll(" ", "")
          .replaceAll("-", "")
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("+", "")
        : null;
    },
    updateProfile() {
      // remove phone and profile_phone items from object
      let {phone, parent_phone, avatar, ...profile} = this.profile;

      // set raw phone number
      profile.phone = this.getNumbersOnly(this.profile.phone);
      profile.parent_phone = this.getNumbersOnly(this.profile.parent_phone);
      profile.avatar =
        this.avatar.indexOf("base64") !== -1 ? null : this.avatar;

      this.$http
        .post(API_ROOT + "/api/user/update", profile)
        .then((res) => {
          const profile = res.body
            ? {...res.body, avatar: this.getAvatar(res.body.avatar)}
            : {};
          this.profile = new Profile(profile);
          this.avatar = this.getAvatar(res.body.avatar);
          const splitedPath = res.body.avatar.split("/");
          const avatarName = splitedPath[splitedPath.length - 1];
          this.isDefaultAvatar = this.defaultAvatars.indexOf(avatarName) !== -1;

          this.$bvModal.show('data-change')
          // Vue.toastr({
          //   description: this.$t('saved_info'),
          //   type: "success",
          // });
        })
        .catch((err) => {
          Vue.toastr({
            description: this.$t('not_saved'),
            type: "success",
          });
        })
        .finally(() => {
        });
    },
    changePasswordHandler() {
      this.$http
        .post(`${API_ROOT}/api/user/update-password`, {
          new_password: this.changePassword.newPassword,
          new_password_repeat: this.changePassword.newPasswordRepeat,
        })
        .then(() => {
          Vue.toastr({
            description: "Пароль успешно изменен!",
            type: "success",
          });
        })
        .catch((err) => console.log(err));
    },
    logoutMethod() {
      localStorage.setItem("token", "");
      localStorage.setItem("user", "");
      window.location = "/";
    },
    goToSection(section) {
      this.$router.push(`/profile_settings/${section}`);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt4M = file.size / 1024 / 1024 < 4;

      if (!(isJPG || isPNG)) {
        this.$message.error("Avatar picture must be JPG or PNG format!");
      }
      if (!isLt4M) {
        this.$message.error("Avatar picture size can not exceed 4MB!");
      }
      return (isJPG || isPNG) && isLt4M;
    },
    handleAvatarSuccess(res, file) {
      if (res.status === "success") {
        let reader = new FileReader();
        reader.readAsDataURL(file.raw);
        reader.onload = (e) => {
          sessionStorage.setItem("avatar-base64", reader.result);
        };
        this.isDefaultAvatar = false;
        this.avatar = this.getAvatar(res.avatar);
        sessionStorage.setItem("avatar", this.getAvatar(res.avatar));
      } else {
        this.$message.error(res.msg);
      }
    },
    chooseDefaultAvatar(avatar,submit=false) {
      this.isDefaultAvatar = true;
      this.avatar = `${window.location.origin}/avatars/${avatar}`;
      if(submit){
        this.submitAvatar()
      }
    },
    submitAvatar() {
      this.$http
        .post(`${API_ROOT}/api/user/avatar/update`, {
          path: this.avatar,
        })
        .then(({body}) => {
          this.profile.avatar = body.avatar;
          this.$bvModal.hide("avatar-select");
        })
        .catch((error) => {
          console.error(error);
          this.$bvModal.hide("avatar-select");
        });
    },
    getAvatar(avatar) {
      return avatar.indexOf("base64") !== -1
        ? avatar
        : avatar.indexOf("http") !== -1
          ? avatar
          : `${window.API_ROOT}/avatar/${avatar}`;
    },
  },

  beforeCreate() {
    Vue.use(VueMask);
  },
  mounted() {
    if (!localStorage.getItem('modal-shown'))
      this.$bvModal.show('slider-modal')
    this.getUserInfo();
  },
};
</script>

<style lang="scss">
.master {
  img {
    top: 58%;
  }

  span {
    top: 94%
  }
}

.profile-user-avatar,
.avatar-round {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.default {
  border-radius: 0;
  background-size: contain;
}

.avatar-upload .el-upload {
  width: 100%;
}
#slider-modal .slider_block,
#slider-modal .modal-dialog{
  height: 600px;
}
#slider-modal .modal-content {
  overflow: hidden;
}
#slider-modal .VueCarousel-slide {
}
#slider-modal .VueCarousel {
  height: 100%;
}
#slider-modal .slider_block::after{
  background-image: none;
}
</style>
